import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ApiKeyRequest} from '../../classes/api-key-request';
import {ApiKeyService} from '../../services/api-key.service';
import {
    AbstractControl,
    AsyncValidatorFn,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {BaseModal} from '../../../../classes/base-modal';
import {AuthenticationService} from '../../../../services/authentication.service';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'eaglei-request-api-key-modal',
    templateUrl: './request-api-key-modal.component.html',
    styleUrls: ['./request-api-key-modal.component.scss'],
})
export class RequestApiKeyModalComponent extends BaseModal implements OnInit {
    get systems(): UntypedFormArray {
        return this.requestGroup.controls.systems as UntypedFormArray;
    }

    @ViewChild('content') content: ElementRef;
    @ViewChild('mask') mask: ElementRef;

    public request: ApiKeyRequest = new ApiKeyRequest();
    public termsOfUse: string[] = [];
    public attributionUrl: string;

    // Validation
    public requestGroup: UntypedFormGroup;

    constructor(
        public ref: MatDialogRef<RequestApiKeyModalComponent>,
        private authenticationService: AuthenticationService,
        private apiKeyService: ApiKeyService,
        private formBuilder: UntypedFormBuilder,
        private router: Router
    ) {
        super();

        this.apiKeyService.getTermsOfUse().subscribe((res: string) => {
            const terms = res.split('\n');
            this.attributionUrl = terms.pop();
            this.termsOfUse = terms.shift().split('|');
            this.termsOfUse.shift();
        });
    }

    ngOnInit(): void {
        const textPattern = Validators.pattern(/[.*[\S].*/);
        const numberPattern = Validators.pattern(/^-?\d+\.?\d*$/);

        const controls = {
            reason: new UntypedFormControl('', [Validators.required, Validators.maxLength(500), textPattern]),
            supportedUsers: new UntypedFormControl('', [numberPattern, Validators.required, Validators.min(1), Validators.max(10000)]),
            dataService: new UntypedFormControl(''),
            ogcService: new UntypedFormControl(''),
            expiration: new UntypedFormControl('', [Validators.requiredTrue]),
            termsOfUse: new UntypedFormControl('', [Validators.requiredTrue]),
            systems: this.formBuilder.array([]),
        };

        this.requestGroup = new UntypedFormGroup(controls);
    }

    afterInit() {
        if ((this.requestGroup.controls.systems as UntypedFormArray).controls.length === 0) {
            this.addSystems();
        }
    }

    /**
     * closes the modal and sends the request back to the calling component.
     */
    public submit(): void {
        const request = new ApiKeyRequest();

        request.reason = this.requestGroup.controls.reason.value;
        request.usersSupported = this.requestGroup.controls.supportedUsers.value;
        request.dataService = this.requestGroup.controls.dataService.value || false;
        request.ogcService = this.requestGroup.controls.ogcService.value || false;
        request.acceptTermsOfUse = this.requestGroup.controls.termsOfUse.value || false;
        request.requestedSystems = (this.requestGroup.controls.systems.value as any[]).map((obj) => obj.system);

        this.ref.close(request);
    }

    /**
     * Closes the modal without taking any action
     */
    public close(): void {
        this.ref.close();
    }

    /**
     * returns if all the fields in the form are valid, This should be used for disabling the submit button
     */
    public isFormValid(): boolean {
        return this.requestGroup.valid && (this.requestGroup.controls.dataService.value || this.requestGroup.controls.ogcService.value);
    }

    /**
     * Creates a new entry in the form array for a dynamic number of systems
     */
    public newSystem(value?: string): AbstractControl {
        return this.formBuilder.group({
            system: [value || '', [Validators.required], [this.uniqueSystemName()]],
        });
    }

    /**
     * Addes a new system text box that is required.
     */
    public addSystems(value?: string): void {
        const newSystem = this.newSystem(value);
        this.systems.push(newSystem);
    }

    public uniqueSystemName(): AsyncValidatorFn {
        return (control) => {
            if (control.value) {
                return this.apiKeyService.validSystemName(control.value).pipe(map((valid) => (valid ? null : {systemName: 'invalid'})));
            }
            return of(null);
        };
    }

    /**
     * Removes the system input at a given index
     * @param index The index in the form array to remove
     */
    public removeSystem(index: number) {
        this.systems.removeAt(index);
    }

    public getSystemError(system: UntypedFormGroup, errorName: string): boolean {
        return system.controls.system.hasError(errorName);
    }

    public getSystemValue(system: UntypedFormGroup): boolean {
        return system.controls.system.value;
    }

    // Using any here because a type mismatch
    public goToApiKey(system: any) {
        const value = this.getSystemValue(system);
        this.router
            .navigate([`app/user/profile/${this.authenticationService.authenticatedUser.getValue().id}`], {state: {apiKeySystem: value}})
            .then(() => this.close());
    }
}
