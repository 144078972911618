import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ApiKeyManagementComponent} from './components/api-key-management/api-key-management.component';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';

const routes: Routes = [
    {
        data: {
            requiredRoles: [GenRoleDefinition.ROLE_USER_MANAGER],
            title: 'API-Key Management',
            useNewDesign: true,
        },
        path: 'manage',
        component: ApiKeyManagementComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ApiKeyRoutingModule {}
