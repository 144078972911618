<div class="dialog-header">
    <span class="filter-header">Request New API-key</span>
    <fa-icon icon="times" class="clickable" (click)="ref.close()"> </fa-icon>
</div>

<div #content class="dialog-content api-key-modal">
    <form [formGroup]="requestGroup">
        <mat-form-field appearance="outline" class="wide ignore-style" subscriptSizing="fixed">
            <mat-label>Reason for Request</mat-label>
            <textarea
                matInput
                matTextareaAutosize
                matAutosizeMaxRows="3"
                matAutosizeMinRows="3"
                name="reason"
                maxlength="500"
                formControlName="reason">
            </textarea>
            <mat-hint align="end">{{ requestGroup.controls.reason.value.length || 0 }} / 500</mat-hint>
            <mat-error>
                <span>A reason is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="half-width ignore-style" subscriptSizing="fixed">
            <mat-label>Number of Users Supported</mat-label>
            <input
                matInput
                eagleiNumberInput
                [allowNegative]="false"
                [allowDecimal]="false"
                name="supportedUsers"
                formControlName="supportedUsers" />
            <mat-hint>
                If support is needed for more than 10,000 users, please contact
                <a href="mailto:eagle-i@ornl.gov">eagle-i&#64;ornl.gov</a>
            </mat-hint>

            <mat-error>
                <span *ngIf="requestGroup.controls.supportedUsers.hasError('pattern')"> Input is not a valid number </span>
                <span *ngIf="requestGroup.controls.supportedUsers.hasError('required')"> A number of users supported is required </span>
                <span *ngIf="requestGroup.controls.supportedUsers.hasError('max')">
                    supporting more than 10,000 users is not permitted, please contact
                    <a href="mailto:eagle-i@ornl.gov">eagle-i&#64;ornl.gov</a>
                </span>
            </mat-error>
        </mat-form-field>

        <div formArrayName="systems">
            <div class="supported-systems">
                <div *ngFor="let system of systems.controls; let i = index">
                    <mat-form-field appearance="outline" class="wide ignore-style" subscriptSizing="fixed" [formGroupName]="i">
                        <mat-label>Supported System</mat-label>
                        <input matInput formControlName="system" />
                        <fa-icon *ngIf="i !== 0" matSuffix icon="times" (click)="removeSystem(i)"></fa-icon>
                        <mat-error>
                            <span *ngIf="getSystemError(system, 'required')"> A system name is required. </span>
                            <span *ngIf="getSystemError(system, 'systemName')">
                                That system name is already is use. Modify existing key <a (click)="goToApiKey(system)"> here</a>
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <button mat-flat-button (click)="addSystems()" color="primary">
            <fa-icon icon="plus"></fa-icon>
            <span>Add Another System</span>
        </button>
    </form>

    <div>
        <p class="section-header">USE CASES</p>
        <form [formGroup]="requestGroup">
            <mat-checkbox formControlName="dataService">For access to Data Services</mat-checkbox>
            <br />
            <mat-checkbox formControlName="ogcService">For access to Open Geospatial Consortium (OGC) Services</mat-checkbox>
        </form>
    </div>

    <div>
        <p class="section-header">Acknowledgement</p>
        <form [formGroup]="requestGroup">
            <mat-checkbox formControlName="expiration">I understand that this API-Key will have to be renewed annually </mat-checkbox>
        </form>
    </div>

    <div>
        <p class="section-header">TERMS OF USE</p>
        <ul class="terms-of-use">
            <li>Attribution URL - {{ attributionUrl }}</li>
            <li *ngFor="let term of termsOfUse">
                {{ term }}
            </li>
        </ul>

        <form [formGroup]="requestGroup">
            <mat-checkbox formControlName="termsOfUse">I accept the Terms of Use</mat-checkbox>
        </form>
    </div>
</div>

<div class="dialog-actions">
    <button mat-stroked-button (click)="close()">Close</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isFormValid()">Submit</button>
</div>
