import {ApiKeyRequest} from './api-key-request';
import {ApiKey} from './api-key';

export class ApiKeyData<K = any> {
    static fromList<K>(keys: ApiKeyRequest[] | ApiKey[]): ApiKeyData<K>[] {
        const dataMap = new Map<string, K[]>();
        const tableData: ApiKeyData<K>[] = [];

        keys.forEach((request) => {
            const mapKey = request.userId;
            const val = dataMap.get(mapKey) || [];
            val.push(request);
            dataMap.set(mapKey, val);
        });

        dataMap.forEach((val, key) => {
            const data = new ApiKeyData<K>();
            data.userId = (val[0] as any).userId;
            data.username = (val[0] as any).userName || (val[0] as any).username;
            data.email = (val[0] as any).email;
            data.keys = val.slice();

            const expirationDates = data.keys.map((k) => (k as any).expirationDateInMs);
            data.minExpiration = Math.min(...expirationDates);
            data.maxExpiration = Math.max(...expirationDates);

            tableData.push(data);
        });

        return tableData;
    }

    public username: string;
    public userId: number;
    public keys: K[];
    public email: string;

    public minExpiration: number;
    public maxExpiration: number;

    constructor() {}

    public createNew(): ApiKeyData<K> {
        const val = new ApiKeyData<K>();
        val.userId = this.userId;
        val.username = this.username;
        val.email = this.email;
        val.keys = this.keys.slice();
        return val;
    }

    public updateExpirationLevels(): void {
        const expirationDates = this.keys.map((k) => (k as any).expirationDateInMs);
        this.minExpiration = Math.min(...expirationDates);
        this.maxExpiration = Math.max(...expirationDates);
    }
}
