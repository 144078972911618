<div class="filter-bar ignore-last">
    <eaglei-text-search placeholder="Search Username or Email" (textChange)="searchRequests($event)"></eaglei-text-search>
</div>

<mat-card>
    <mat-card-content>
        <div class="table-container">
            <table
                #materialTable
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="requested"
                matSortDirection="desc"
                multiTemplateDataRows>
                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Name
                        <eaglei-table-sort-header [columnName]="'username'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let request">
                        <p class="ellipsis">{{ request.username }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="numberRequested">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        # Requested
                        <eaglei-table-sort-header [columnName]="'numberRequested'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let request" colspan="8">
                        <div class="requested-expand">
                            <p class="ellipsis">{{ request.keys.length }}</p>
                            <fa-icon [icon]="request === expanded ? 'chevron-up' : 'chevron-down'"></fa-icon>
                        </div>
                    </td>
                </ng-container>

                <!-- These columns are just used for headers -->
                <ng-container matColumnDef="dateRequested">
                    <th mat-header-cell *matHeaderCellDef>Date Requested</th>
                    <td mat-cell *matCellDef="let request"></td>
                </ng-container>

                <ng-container matColumnDef="userSupported">
                    <th mat-header-cell *matHeaderCellDef>Users Supported</th>
                    <td mat-cell *matCellDef="let request"></td>
                </ng-container>

                <ng-container matColumnDef="systemSupported">
                    <th mat-header-cell *matHeaderCellDef>System Supported</th>
                    <td mat-cell *matCellDef="let request"></td>
                </ng-container>

                <ng-container matColumnDef="dataService">
                    <th mat-header-cell *matHeaderCellDef class="center">Data Services</th>
                    <td mat-cell *matCellDef="let request" class="center"></td>
                </ng-container>

                <ng-container matColumnDef="ogcService">
                    <th mat-header-cell *matHeaderCellDef class="center">OGC Services</th>
                    <td mat-cell *matCellDef="let request" class="center"></td>
                </ng-container>

                <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef>Reason</th>
                    <td mat-cell *matCellDef="let request"></td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="ei-md-column"></th>
                    <td mat-cell *matCellDef="let request" class="ei-md-column"></td>
                </ng-container>
                <!--End Header Columns-->

                <!--Expanded columns-->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let info" [attr.colspan]="columnNames.length" class="expanded-cell">
                        <div class="hide-expanded-row" [class.show-expanded-row]="info === expanded">
                            <table
                                mat-table
                                [dataSource]="expandedDataSource"
                                matSort
                                [matSortDisableClear]="true"
                                matSortActive="requested"
                                matSortDirection="desc"
                                class="expanded-table">
                                <ng-container matColumnDef="blank">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let request"></td>
                                </ng-container>

                                <ng-container matColumnDef="dateRequested">
                                    <th mat-header-cell *matHeaderCellDef>Date Requested</th>
                                    <td mat-cell *matCellDef="let request">
                                        <p class="ellipsis">{{ request.requestDate | momentDate : 'M/DD/YYYY' }}</p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="userSupported">
                                    <th mat-header-cell *matHeaderCellDef>Users Supported</th>
                                    <td mat-cell *matCellDef="let request">
                                        <p class="ellipsis">{{ request.usersSupported | eagleiNumber }}</p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="systemSupported">
                                    <th mat-header-cell *matHeaderCellDef>System Supported</th>
                                    <td mat-cell *matCellDef="let request">
                                        <p
                                            #supportedSystem
                                            class="ellipsis"
                                            [matTooltipDisabled]="!hasEllipsis(supportedSystem)"
                                            [matTooltip]="request.system"
                                            matTooltipPosition="above"
                                            matTooltipShowDelay="250">
                                            {{ request.system }}
                                        </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="dataService">
                                    <th mat-header-cell *matHeaderCellDef class="center">Data Services</th>
                                    <td mat-cell *matCellDef="let request" class="center">
                                        <fa-icon *ngIf="request.dataService" [icon]="['fas', 'check']"></fa-icon>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ogcService">
                                    <th mat-header-cell *matHeaderCellDef class="center">OGC Services</th>
                                    <td mat-cell *matCellDef="let request" class="center">
                                        <fa-icon *ngIf="request.ogcService" [icon]="['fas', 'check']"></fa-icon>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="reason">
                                    <th mat-header-cell *matHeaderCellDef>Reason</th>
                                    <td mat-cell *matCellDef="let request">
                                        <p
                                            #reason
                                            class="ellipsis"
                                            [matTooltipDisabled]="!hasEllipsis(reason)"
                                            [matTooltip]="request.reason"
                                            matTooltipPosition="above"
                                            matTooltipShowDelay="250">
                                            {{ request.reason }}
                                        </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef class="ei-md-column"></th>
                                    <td
                                        mat-cell
                                        *matCellDef="let request"
                                        class="ei-md-column"
                                        matTooltip="Unable to modify request of disabled user."
                                        matTooltipShowDelay="250"
                                        matTooltipPosition="above"
                                        [matTooltipDisabled]="request.userEnabled">
                                        <div class="action-buttons">
                                            <button
                                                [disabled]="!request.userEnabled || pendingApproval"
                                                mat-stroked-button
                                                [matMenuTriggerFor]="denyOptions">
                                                Deny
                                            </button>
                                            <button
                                                [disabled]="!request.userEnabled || pendingApproval"
                                                mat-flat-button
                                                color="primary"
                                                (click)="approveRequest(request, $event)">
                                                {{ approvalButtonText }}
                                            </button>

                                            <mat-menu #denyOptions="matMenu" overlapTrigger>
                                                <div *ngFor="let reason of denialReasons">
                                                    <button
                                                        mat-button
                                                        class="ellipsis deny-option"
                                                        (click)="denyRequest(request, reason)"
                                                        [matTooltip]="reason"
                                                        matTooltipPosition="left"
                                                        matTooltipShowDelay="250">
                                                        {{ reason }}
                                                    </button>
                                                </div>
                                                <button mat-button (click)="createDenyOption(request)" class="deny-option">
                                                    Create New Deny Option
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-row *matRowDef="let row; columns: expandColumns"></tr>
                            </table>
                        </div></td
                ></ng-container>

                <!--Row Definitions-->
                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: mainColumns"
                    class="clickable hoverable"
                    (click)="toggleExpansion(row)"
                    [class.selected-row]="row === expanded"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['expandedDetail']"
                    class="expandable-row"
                    [class.expanded]="row === expanded"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
