import {Component, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ApiKey} from '../../classes/api-key';
import {DenyOptionsComponent} from '../../../../../shared/modals/deny-options/deny-options.component';
import {filter} from 'rxjs/operators';
import {ApiKeyService} from '../../services/api-key.service';
import {ApplicationConfig} from '../../../../classes/application-config';
import {LoadingMaskOptions} from '../../../../classes/loading-mask-options';
import {ModalConfig} from '../../../../classes/modal-config';
import {ApiKeyData} from '../../classes/api-key-data';
import {BehaviorSubject} from 'rxjs';
import moment from 'moment';

class ApiKeyStatus {
    static ALL: string = 'all';
    static ACTIVE: string = 'active';
    static EXPIRED: string = 'expired';

    static values(): string[] {
        return [ApiKeyStatus.ALL, ApiKeyStatus.ACTIVE, ApiKeyStatus.EXPIRED];
    }
}

// tslint:disable-next-line:max-classes-per-file
@Component({
    selector: 'eaglei-api-key-info',
    templateUrl: './api-key-info.component.html',
    styleUrls: ['./api-key-info.component.scss'],
})
export class ApiKeyInfoComponent {
    // HTML Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // DataSource methods
    public dataSource: MatTableDataSource<ApiKeyData<ApiKey>>;
    public expandedDataSource: MatTableDataSource<ApiKey>;
    public readonly columnNames: string[] = [
        'name',
        'requested',
        'status',
        'apiKey',
        'expiration',
        'system',
        'dataService',
        'ogcService',
        'comment',
        'action',
    ];

    public readonly mainColumns: string[] = ['name', 'requested', 'action'];
    public readonly expandedColumns: string[] = [
        'blank',
        'blank',
        'status',
        'apiKey',
        'expiration',
        'system',
        'dataService',
        'ogcService',
        'comment',
        'blank',
    ];

    public expanded: ApiKeyData;

    private allKeys: ApiKeyData<ApiKey>[];
    private allKeysBs = new BehaviorSubject<ApiKeyData<ApiKey>[]>([]);

    public keyStatuses = ApiKeyStatus.values();
    public selectedStatus: string = ApiKeyStatus.ALL;
    public readonly expirationReasons: string[] = ['User account has been deactivated.', 'No longer needed.'];

    constructor(private apiKeyService: ApiKeyService, private modal: MatDialog, private popup: MatSnackBar) {
        this.showMask();
        this.expandedDataSource = new MatTableDataSource<ApiKey>();
        this.apiKeyService.getApiKeys().subscribe((keys) => {
            const data = ApiKeyData.fromList<ApiKey>(keys);
            this.allKeys = data.slice();
            this.allKeysBs.next(data.slice());

            this.initializeData(data);
        });
    }

    /**
     * Updates the data in the table, if there is no current data all the sorting, paging, and filtering is set up.
     * @param apiKeys The API-KEY's that will be in the table.
     */
    private initializeData(apiKeys: ApiKeyData<ApiKey>[]) {
        if (this.dataSource) {
            this.dataSource.data = apiKeys;
        } else {
            this.dataSource = new MatTableDataSource<ApiKeyData<ApiKey>>(apiKeys);
            this.dataSource.sortingDataAccessor = this.sortAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }
        this.hideMask();
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Filters the table based off of username and expired status
     * @param apiKey The API-KEY to be tested
     * @param text The username to search for.
     */
    private filterPredicate(apiKey: ApiKey, text: string): boolean {
        return apiKey.username.toLowerCase().includes(text.trim());
    }

    /**
     * Sorts the table based off the column name
     * @param data The API-KEY to be tested.
     * @param columnName The column name being sorted.
     */
    private sortAccessor(data: ApiKeyData<ApiKey>, columnName: string): string | number {
        switch (columnName) {
            case 'name':
                return data.username.toLowerCase();
            case 'requested':
                return data.keys.length;
            case 'expiration':
                return this.sort.direction === 'asc' ? data.minExpiration : data.maxExpiration;
            default:
                return '';
        }
    }

    // Filter Methods
    /**
     * Filters the table to usernames that contain the text
     * @param searchText The text to be searched.
     */
    public searchUsername(searchText: string): void {
        searchText = searchText === '' ? ' ' : searchText;
        this.dataSource.filter = searchText;
    }

    /**
     * Filters the table down to the selected status.
     * @param status The new status being selected
     */
    public filterStatus(status: string) {
        this.selectedStatus = status;

        if (this.selectedStatus === ApiKeyStatus.ALL) {
            this.dataSource.data = this.allKeys.slice();
        } else {
            const tmpKeys = this.allKeys.map((k) => k.createNew());
            this.dataSource.data = tmpKeys.filter((data) => {
                data.keys = data.keys.filter((key) => {
                    return (
                        (this.selectedStatus === ApiKeyStatus.ACTIVE && !key.expired) ||
                        (this.selectedStatus === ApiKeyStatus.EXPIRED && key.expired)
                    );
                });

                return data.keys.length > 0;
            });

            this.dataSource.data.forEach((data) => data.updateExpirationLevels());
        }

        this.searchUsername(this.dataSource.filter);
    }

    // Utility Methods
    /**
     * Expires the selected API-Key
     * @param data The API-Key to be expired.
     * @param reason The reason the API-Key is being expired.
     * @param event the click event on the button.
     */
    public expireApiKey(data: ApiKeyData<ApiKey>, reason: string, event?: MouseEvent): void {
        event?.stopPropagation();

        const success = (res: ApiKey[]) => {
            this.popup.open(`API-Key Expired for ${res[0].username}`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});

            // Setting the expiration like this so the table picks up the changes
            data.keys.forEach((key) => {
                key.expirationDate = moment();
                key.expired = true;
            });

            this.expandedDataSource._updateChangeSubscription();
        };

        const failure = (error: any) => {
            const errorText = error.userMessage || 'Error Expiring API-Key';
            this.popup.open(errorText, 'Okay', {panelClass: 'dialog-failure'});
            throw error;
        };

        this.apiKeyService.expireAllUserApiKeys(data.userId, reason).subscribe(success, failure);
    }

    /**
     * Creates a custom expiration reason
     * @param data The API-Key that will be expired.
     */
    public createExpirationReason(data: ApiKeyData): void {
        const opts: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
            data: {header: 'Expiration', button: 'Expire API-Key'},
        };
        this.modal
            .open(DenyOptionsComponent, opts)
            .afterClosed()
            .pipe(filter((reason) => !!reason))
            .subscribe((reason) => this.expireApiKey(data, reason));
    }

    // Mask Methods
    /**
     * Configures and displays the loading mask
     */
    public showMask(): void {
        const config = new LoadingMaskOptions();
        config.showMask = true;
        ApplicationConfig.pageMask.next(config);
    }

    /**
     * Hides the loading mask.
     */
    public hideMask(): void {
        const config = ApplicationConfig.pageMask.getValue();
        config.showMask = false;
        ApplicationConfig.pageMask.next(config);
    }

    /**
     * Toggles the expanded row
     * @param row the row to be expanded.
     */
    public toggleExpansion(row: ApiKeyData): void {
        this.expanded = this.expanded === row ? (undefined as any) : row;

        if (this.expanded) {
            this.expandedDataSource.data = this.expanded.keys;
            this.expandedDataSource._updateChangeSubscription();
        }
    }

    public allKeysExpired(key: ApiKeyData<ApiKey>): boolean {
        return key.keys.every((k) => k.expired);
    }
}
