<div class="filter-bar ignore-last">
    <eaglei-text-search placeholder="Search Username" (textChange)="searchUsername($event)"> </eaglei-text-search>

    <mat-button-toggle-group name="status" [value]="selectedStatus" class="api-key-status-filter">
        <mat-button-toggle [value]="status" *ngFor="let status of keyStatuses" (change)="filterStatus(status)">
            {{ status | titlecase }}
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>

<div class="table-container">
    <table
        mat-table
        [dataSource]="dataSource"
        matSort
        [matSortDisableClear]="true"
        matSortActive="name"
        matSortDirection="asc"
        multiTemplateDataRows>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Username
                <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let data">
                <p class="ellipsis">{{ data.username }}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="requested">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                # Requested
                <eaglei-table-sort-header [columnName]="'requested'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let data" colspan="8">
                <p class="ellipsis">{{ data.keys.length }}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let key"></td>
        </ng-container>

        <ng-container matColumnDef="apiKey">
            <th mat-header-cell *matHeaderCellDef class="key-column">API-Key</th>
            <td mat-cell *matCellDef="let key" class="key-column"></td>
        </ng-container>

        <ng-container matColumnDef="expiration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Expiration
                <eaglei-table-sort-header [columnName]="'expiration'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let request"></td>
        </ng-container>

        <ng-container matColumnDef="system">
            <th mat-header-cell *matHeaderCellDef>System Supported</th>
            <td mat-cell *matCellDef="let key"></td>
        </ng-container>

        <ng-container matColumnDef="dataService">
            <th mat-header-cell *matHeaderCellDef class="center">Data Services</th>
            <td mat-cell *matCellDef="let request" class="center"></td>
        </ng-container>

        <ng-container matColumnDef="ogcService">
            <th mat-header-cell *matHeaderCellDef class="center">OGC Services</th>
            <td mat-cell *matCellDef="let request" class="center"></td>
        </ng-container>

        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>Comments</th>
            <td mat-cell *matCellDef="let key"></td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let key">
                <div class="action-buttons">
                    <button
                        mat-stroked-button
                        *ngIf="!allKeysExpired(key)"
                        [matMenuTriggerFor]="denyOptions"
                        (click)="$event.stopPropagation()">
                        Expire API-Keys
                    </button>

                    <mat-menu #denyOptions="matMenu" overlapTrigger>
                        <div *ngFor="let reason of expirationReasons">
                            <button
                                mat-button
                                class="ellipsis deny-option"
                                (click)="expireApiKey(key, reason, $event)"
                                [matTooltip]="reason"
                                matTooltipPosition="left"
                                matTooltipShowDelay="250">
                                {{ reason }}
                            </button>
                        </div>
                        <button mat-button (click)="createExpirationReason(key)" class="deny-option">Create New Deny Option</button>
                    </mat-menu>
                </div>
            </td>
        </ng-container>

        <!--Expanded columns-->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let info" [attr.colspan]="columnNames.length" class="expanded-cell">
                <div class="hide-expanded-row" [class.show-expanded-row]="info === expanded">
                    <table mat-table [dataSource]="expandedDataSource" class="expanded-table">
                        <ng-container matColumnDef="blank">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let request"></td>
                        </ng-container>

                        <ng-container matColumnDef="apiKey">
                            <th mat-header-cell *matHeaderCellDef class="key-column">API-Key</th>
                            <td mat-cell *matCellDef="let key" class="key-column">
                                <p class="ellipsis">{{ key.key }}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expiration">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Expiration
                                <eaglei-table-sort-header [columnName]="'expiration'" [sort]="sort"></eaglei-table-sort-header>
                            </th>
                            <td mat-cell *matCellDef="let request">
                                <p class="ellipsis">{{ request.expirationDate | momentDate : 'M/DD/YYYY' }}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let key">
                                <p class="ellipsis">{{ key.expired ? 'Expired' : 'Active' }}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="system">
                            <th mat-header-cell *matHeaderCellDef>System Supported</th>
                            <td mat-cell *matCellDef="let key">
                                <p class="ellipsis">{{ key.system }}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="dataService">
                            <th mat-header-cell *matHeaderCellDef class="center">Data Services</th>
                            <td mat-cell *matCellDef="let request" class="center">
                                <fa-icon *ngIf="request.dataService" icon="check"></fa-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ogcService">
                            <th mat-header-cell *matHeaderCellDef class="center">OGC Services</th>
                            <td mat-cell *matCellDef="let request" class="center">
                                <fa-icon *ngIf="request.ogcService" icon="check"></fa-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef>Comments</th>
                            <td
                                mat-cell
                                *matCellDef="let key"
                                [matTooltip]="key.comments"
                                matTooltipShowDelay="250"
                                matTooltipPosition="above">
                                <p class="ellipsis">{{ key.comments }}</p>
                            </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: expandedColumns"></tr>
                    </table>
                </div></td
        ></ng-container>

        <!--Row Definitions-->
        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: mainColumns"
            class="clickable hoverable"
            (click)="toggleExpansion(row)"
            [class.selected-row]="row === expanded"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row" [class.expanded]="row === expanded"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
</div>
